import picsureIcon from "./assets/images/logo/picsure_logo.svg";
import picsureIconSmall from "./assets/images/logo/picsure_logo_small.svg";

export default {
  apiUrl: "https://api.picsure.ai"
};
const siteConfig = {
  siteName: "Picsure - Buildings management webapp",
  siteIconBig: picsureIcon,
  siteIcon: picsureIcon,
  footerText: "Picsure © " + new Date().getFullYear()
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themePicsure",
  layout: "themedefault",
  theme: "themedefault"
};
const language = "english";

export { siteConfig, themeConfig, language };
