import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Popover } from "antd";
import LanguageSwitcher from '../../containers/LanguageSwitcher/topBar';
import TopbarDropdownWrapper from './topbarDropdownSmall.style';

class TopbarLanguage extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,

    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  hideHandle = () => {
    this.hide();
};

  render() {

    const iconStyle = {
      'fontSize': '12px',
      'marginLeft': '2px',
      'verticalAlign': '0px'
    }
    const content = (
      <TopbarDropdownWrapper className="topbarLanguage">

        <div className="isoDropdownBody" onClick={this.hideHandle}>
          <LanguageSwitcher  />
        </div>

      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">


        {this.props.LanguageSwitcher.language.locale.toUpperCase()}
        {/* <DownOutlined style={iconStyle} /> */}




        </div>
      </Popover>
    );
  }
}

export default connect(state => ({
  ...state.App.toJS(),
  customizedTheme: state.ThemeSwitcher.toJS().topbarTheme,
  LanguageSwitcher: state.LanguageSwitcher.toJS()
}))(TopbarLanguage);
