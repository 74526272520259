import qs from "qs";
import { push } from "react-router-redux";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { clearToken, performRequest } from "../../helpers/utility";
import actionsProject from "../asset/actions";
import actions from "./actions";
const DEV = process.env.NODE_ENV !== "production";

//worker
export function* loginRequestAsync(action) {
  try {
    DEV && console.log("LOGIN ACTION");
    const formData = qs.stringify({
      username: action.username,
      password: action.password,
    });
    // Call utility function for request  - authentication flag false because auth not by token in header
    const responseData = yield call(performRequest, "post", "/api/v1/auth/jwt/create", process.env.PICSURE_HOST_API, formData, false, {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });


    //Temp
    // yield put({
    //   type: actions.LOGIN_SUCCESS,
    //   access_token: "foo",
    //   refresh_token: "baz",
    // });


    DEV && console.log("login response", responseData);
    let responseDetail = responseData.detail;
    if (!responseData.access_token) {
      yield put({ type: actions.LOGIN_ERROR, errorMessage: "Oops, your username and password does not match" });
    } else {
      yield put({
        type: actions.LOGIN_SUCCESS,
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
      });
    }
  } catch (error) {
    DEV && console.log("login error", error);
    if (error.response && error.response.status === 400) {
      DEV && console.log("unauthorized, logging out ...");
      yield put({ type: actions.LOGIN_ERROR, errorMessage: "Oops, your username and password does not match" });
    } else {
      yield put({ type: actions.LOGIN_ERROR, errorMessage: "There was an error at login" });
    }
  }
}

export function* loginSuccessAsync(payload) {
  //yield localStorage.setItem('id_token', payload.token);
  yield localStorage.setItem("access_token", payload.access_token);
  yield localStorage.setItem("refresh_token", payload.refresh_token);
  yield put(push("/app/dashboard"));
}

export function* loginErrorAsync() {
  yield clearToken();
}

export function* logoutAsync() {
  yield clearToken();
  yield put(push("/"));
}

export function* reauthenticateAsync(action) {
  DEV && console.log("REAUTH");

  try {
    const data = JSON.stringify({
      refresh_token: localStorage.getItem("refresh_token"),
	});

    // const response = yield call(performRequest, "post", "/api/v1/auth/jwt/refresh", "/proxy", data, false, {
    const response = yield call(performRequest, "post", "/api/v1/auth/jwt/refresh", process.env.PICSURE_HOST_API, data, false, {
     'Accept': 'application/json',
     'Content-Type': 'application/json'
	});
	


  //   DEV && console.log("reauth response", response);

    // yield put({
    //   type: actions.REAUTHENTICATE_SUCCESS,
    //   access_token: localStorage.getItem("refresh_token"),
    //   from: action.from,
    // });

    yield put({
      type: actions.REAUTHENTICATE_SUCCESS,
      access_token: response.access_token,
      from: action.from,
    });
  } catch (error) {
    DEV && console.log("re-auth error", error);
    //logout
    
      yield put({ type: actions.LOGOUT });

  }
}

export function* reauthenticateSuccessAsync(payload) {
  //Store new token at local store
  clearToken();
  yield localStorage.setItem("access_token", payload.access_token);
  DEV && console.log("reauth success payload", payload);
  //If route- signin push dashboard
  if (payload.from) {
    DEV && console.log("redirect to:", payload.from);
    let location = payload.from || "/app/dashboard";
    yield put(push(location));
  }
}

export function* reauthenticateErrorAsync(payload) {
  //Store new token at local store
  clearToken();
  yield put(push("/"));
}

export function* saveUserAsync(action) {
  DEV && console.log("SAVE_USER_REQUEST", action);

  const user = action.data;
  try {
    //get new token which is valid longer
    const response = yield call(performRequest, "put", `customerprofile/${action.id}/`, "/proxy", action.data, true);

    DEV && console.log("saveUser response", response);
    yield put({
      type: actions.SAVEUSER_SUCCESS,
      message: "User saved successfully",
      user: user,
    });
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put({ type: actions.SAVEUSER_ERROR, message: "There was an error at saving your profile" });
    } else {
      DEV && console.log("unauthorized, try to reauth ...");

      yield put({ type: actions.REAUTHENTICATE_REQUEST });
    }
    DEV && console.log("saveUsererror", error);
  }
}

export function* getUserAsync(action) {
  try {
    DEV && console.log("ACTION", action);

    //get new token which is valid longer
    const response = yield call(performRequest, "get", `customerprofile/${action.id}`, "/proxy", {}, true);
    DEV && console.log("getuser response", response);

    yield put({
      type: actions.GETUSER_SUCCESS,
      user: response.user,
    });
  } catch (error) {
    DEV && console.log("getUser error", error);

    yield put({ type: actions.GETUSER_ERROR });
  }
}

//watcher
export function* watchLogin() {
  yield takeLatest(actions.LOGIN_REQUEST, loginRequestAsync);
}

export function* watchLoginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, loginSuccessAsync);
}

export function* watchLoginError() {
  yield takeEvery(actions.LOGIN_ERROR, loginErrorAsync);
}

export function* watchLogout() {
  yield takeLatest(actions.LOGOUT, logoutAsync);
}

export function* watchReauthenticate() {
  yield takeLatest(
    [
      actions.REAUTHENTICATE_REQUEST,
      actions.SAVEUSER_REQUEST,
      // actionsProject.PROJECT_GET_SUCCESS,
      // actionsProject.PROJECT_SAVE_SUCCESS,
      // actionsProject.PROJECT_CREATE_SUCCESS,
    ],
    reauthenticateAsync
  );
}

export function* watchReauthenticateSuccess() {
  yield takeLatest(actions.REAUTHENTICATE_SUCCESS, reauthenticateSuccessAsync);
}

export function* watchSaveUser() {
  yield takeLatest(actions.SAVEUSER_REQUEST, saveUserAsync);
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLoginSuccess),
    fork(watchLoginError),
    fork(watchReauthenticate),
    fork(watchReauthenticateSuccess),
    fork(watchSaveUser),
    fork(watchLogout),
  ]);
  // yield all([fork(watchLogin), fork(watchLoginSuccess), fork(watchLoginError), fork(watchSaveUser), fork(watchLogout)]);
}
