import React from "react";
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import { Toaster } from "react-hot-toast";

const PicsureApp = () => (
  <Provider store={store}>
    <div>
      <PublicRoutes history={history} />
    </div>
  </Provider>
);

export default PicsureApp;
